// extracted by mini-css-extract-plugin
export const container = "search-module--container--2FRG4";
export const filters = "search-module--filters--3aOih";
export const entities = "search-module--entities--cDkA2";
export const subTitle = "search-module--subTitle--3MBCf";
export const flexContainer = "search-module--flexContainer--1W2zs";
export const inline = "search-module--inline--2dzeN";
export const small = "search-module--small--2dH8r";
export const placeholder = "search-module--placeholder--9IaM1";
export const width25 = "search-module--width25--2_kDo";
export const width40 = "search-module--width40--2WIt-";
export const width50 = "search-module--width50--2ZnKB";
export const width60 = "search-module--width60--96Iwx";
export const width75 = "search-module--width75--3s1x9";
export const width70 = "search-module--width70--T6qsO";
export const floatLeft = "search-module--floatLeft--21KfM";
export const rightItem = "search-module--rightItem--3sPVS";
export const myAccordion = "search-module--myAccordion--2CDbN";
export const pad = "search-module--pad--2BeIf";
export const mx15 = "search-module--mx15--6WSW_";
export const subSearch = "search-module--subSearch--2hzf1";