/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ImageSize {
  LARGE = "LARGE",
  MEDIUM = "MEDIUM",
  SMALL = "SMALL",
}

/**
 * ISO-8601 formatted string of format: 2017-06-25
 */
export interface DateRangeInput {
  readonly from: string;
  readonly to: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
